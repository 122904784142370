import './App.css';
import React, {Suspense, useEffect} from "react";
import LoadingPage from "./components/loadingpage";
import {BrowserRouter, Route, Routes} from 'react-router-dom';

const LazyLandingPageStore = React.lazy(() => import("./pages/lp-store"));
const LazyLandingPageUser = React.lazy(() => import("./pages/lp-user"));

function App() {
    const LIST_ROUTE = [
        {slug: 'attiva-il-menu-digitale-per-la-tua-attivita', element: <LazyLandingPageStore/>},
        {slug: 'offerta-esclusiva-per-te', element: <LazyLandingPageStore/>},
        {slug: 'offerta-esclusiva-per-i-tuoi-clienti', element: <LazyLandingPageUser/>},
    ];
    const Page404 = () => {
        useEffect(() => {
            window.location.href = '//business.gloowe.com';
        }, []);
        return null;
    };

    return (
        <Suspense fallback={<LoadingPage/>}>
            <BrowserRouter>
                <Routes>
                    {
                        LIST_ROUTE.map((route, key) =>
                            <Route key={key} path={"/" + route.slug} element={route.element}/>
                        )
                    }
                    <Route path="/*" element={<Page404/>}/>
                </Routes>
            </BrowserRouter>
        </Suspense>
    );
}

export default App;
